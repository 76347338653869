export const environment = {
    production: false,
    //apiUrl: 'https://broken-night-7120.getsandbox.com',
    apiUrl:'https://quality-gate-stg-api.enacloud.me',

    apiUrlBuffer: '',

    client: '',
    redirectUri: 'http://localhost:4200',
    authority: '',
  };
